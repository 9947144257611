/* eslint-disable no-nested-ternary */
/* eslint-disable no-plusplus */
import { AddRounded, EditRounded, SearchRounded } from '@mui/icons-material';
import {
	Box,
	Chip,
	IconButton,
	InputAdornment,
	MenuItem,
	Stack,
	Switch,
	Tab,
	Tabs,
	Tooltip,
	Typography,
} from '@mui/material';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { getAllBrand } from 'api/brand';
import { getAllCategory } from 'api/categories';
import { DeleteCoupon, getAllCoupon } from 'api/coupon';
import { getAllPartners } from 'api/partners';
import { getAllCountry } from 'api/seeder';
import { toggleTrending } from 'api/trending';
import Button from 'components/common/Button';
import Header from 'components/common/Header';
import Input from 'components/common/Input';
import Table from 'components/common/Table';
import TableLayout from 'components/common/TableLayout';
import { debounce } from 'lodash';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
	clearData,
	couponSlice,
	toggleLoading,
} from 'redux/coupon/couponSlice';
import { RootState } from 'redux/store';
import { rewardType } from 'utils/constant';
import getArabicTime from 'utils/dateConverter';
import useUrlSearchParams from 'utils/useUrlSearchParams';

import styles from './index.module.scss';

interface TabPanelProps {
	children: any;
	index: number;
	value: number;
}

const TabPanel = (props: TabPanelProps) => {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ paddingTop: '30px' }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
};

const Coupon = () => {
	// const [page, setPage] = useState(1);
	const { searchParams, setParams } = useUrlSearchParams(
		'filter',
		'couponType',
		'partner',
		'query'
	);
	const [statusFilter, setStatusFilter] = useState(searchParams?.filter ?? 1);
	const [partnerFilter, setPartnerFilter] = useState(searchParams?.partner ?? 2);
	const [values, setValue] = useState(
		searchParams?.couponType ? Number(searchParams?.couponType) : 0
	);

	const [query, setQuery] = useState<string>(searchParams?.query ?? undefined);

	const dispatch = useDispatch();

	const { allCoupons, pagination, loading } = useSelector(
		(state: RootState) => state.coupon
	);

	useEffect(() => {
		dispatch(getAllBrand({}));
		dispatch(getAllCountry());
		dispatch(getAllCategory({}));
		dispatch(getAllPartners({}));
		dispatch(clearData());
	}, []);
	const fetchCoupons = (q?: string) => {
		dispatch(
			getAllCoupon({
				page: pagination?.currentPage,
				couponType: values === 0 ? 2 : values === 1 ? 1 : 3,
				statusFilter,
				partnerFilter,
				q: q ?? query,
				sortBy: 'newest',
				perPage: pagination?.perPage,
			})
		);
	};
	useEffect(() => {
		fetchCoupons();
	}, [
		pagination?.currentPage,
		values,
		statusFilter,
		partnerFilter,
		pagination?.perPage,
	]);

	const debouncedFn = useCallback(
		debounce(q => fetchCoupons(q), 500),
		[]
	);

	const handleChange = (event: any, newValue: number) => {
		setValue(newValue);
		setParams('couponType', newValue);
		// reset current Page when tab changes
		dispatch(couponSlice.actions.setCurrentPage(1));
	};

	const couponTable: GridColDef[] = [
		{
			field: 'brandName',
			headerName: 'Brand Name',
			width: 200,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={params?.row?.brand?.brandTranslation?.en?.[0]?.name}>
					<span className='textWrap'>
						{params?.row?.brand?.brandTranslation?.en?.[0]?.name}
					</span>
				</Tooltip>
			),
		},
		{
			field: 'brandImage',
			headerName: 'Brand Image',
			width: 300,
			renderCell: (params: GridValueGetterParams) => (
				<Box width='200px' height='100px'>
					<img
						src={`${params?.row?.brand?.brandTranslation?.en?.[0]?.imageSlug?.path}`}
						alt=''
						width='100%'
						height='100px'
						style={{
							backgroundPosition: 'center center',
							objectFit: 'contain',
							padding: '0.5rem',
						}}
					/>
				</Box>
			),
		},
		{
			field: 'brandImageAra',
			headerName: 'Brand Image (Arabic)',
			width: 300,
			renderCell: (params: GridValueGetterParams) => (
				<Box width='200px' height='100px'>
					<img
						src={`${params?.row?.brand?.brandTranslation?.ara?.[0]?.imageSlug?.path}`}
						alt=''
						width='100%'
						height='100px'
						style={{
							backgroundPosition: 'center center',
							objectFit: 'contain',
							padding: '0.5rem',
						}}
					/>
				</Box>
			),
		},
		{
			field: 'categories',
			headerName: 'Categories',
			width: 200,
			renderCell: (params: GridValueGetterParams) => {
				const categoriesArray = params?.row?.category;
				const arr = [];
				for (let i = 0; i < categoriesArray.length; i++) {
					arr.push(
						categoriesArray[i].translation.filter(
							(item: any) => item.languageCode === 'en'
						)?.[0].name
					);
				}
				return (
					<Tooltip title={arr.join(', ')}>
						<div className={styles.workBreak}>{arr.join(', ') || '-'}</div>
					</Tooltip>
				);
			},
		},
		{
			field: 'couponCode',
			headerName:
				values === 0
					? 'Coupon Code'
					: values === 1
					? 'Cashback Code'
					: 'Offer Code',
			width: 200,
			// valueGetter: (params: GridValueGetterParams) => `${params?.row?.couponCode}`,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip
					title={params?.row?.couponType === 3 ? 'N/A' : params?.row?.couponCode}
				>
					<span className='textWrap'>
						{params?.row?.couponType === 3 ? 'N/A' : params?.row?.couponCode}
					</span>
				</Tooltip>
			),
		},
		{
			field: 'partner name',
			headerName: 'Partner',
			width: 200,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={params?.row?.partner?.name}>
					<span className='textWrap'>
						{params?.row?.partner?.name ? params?.row?.partner?.name : 'Direct'}
					</span>
				</Tooltip>
			),
		},
		// {
		// 	field: 'qyubic affiliate',
		// 	headerName: 'Qyubic Affiliate',
		// 	width: 200,
		// 	renderCell: (params: GridValueGetterParams) => (
		// 		<Tooltip title={params?.row?.consumerPartner?.name || '-'}>
		// 			<span className='textWrap'>
		// 				{params?.row?.consumerPartner?.name
		// 					? params?.row?.consumerPartner?.name
		// 					: '-'}
		// 			</span>
		// 		</Tooltip>
		// 	),
		// },

		{
			field: 'rewardValue',
			headerName: 'Discount Amount',
			width: 200,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip
					title={
						(rewardType[params.row.rewardType - 1] === 'Text'
							? params?.row?.translation?.en?.[0]?.text
							: params?.row?.rewardValue) || 'N/A'
					}
				>
					<span>
						{(rewardType[params.row.rewardType - 1] === 'Text'
							? params?.row?.translation?.en?.[0]?.text
							: params?.row?.rewardValue) || 'N/A'}
					</span>
				</Tooltip>
			),
		},

		{
			field: 'rewardType',
			headerName: 'Discount Type',
			width: 200,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={rewardType[params.row.rewardType - 1]}>
					<span className='textWrap'>{rewardType[params.row.rewardType - 1]}</span>
				</Tooltip>
			),
		},
		{
			field: 'link',
			headerName: 'Website Link',
			width: 200,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={params?.row?.link || 'N/A'}>
					<a
						className='textWrap'
						href={params?.row?.link}
						target='_blank'
						rel='noreferrer'
					>
						{params?.row?.link || 'N/A'}
					</a>
				</Tooltip>
			),
		},

		{
			field: 'description',
			headerName: 'Description',
			width: 200,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={params?.row?.translation?.en?.[0].description || '-'}>
					<span className='textWrap'>
						{params?.row?.translation?.en?.[0].description || '-'}
					</span>
				</Tooltip>
			),
		},
		{
			field: 'country',
			headerName: 'Country',
			width: 200,
			renderCell: (params: GridValueGetterParams) => {
				const countryArray = params?.row?.country;
				const arr = [];
				for (let i = 0; i < countryArray.length; i++) {
					arr.push(
						countryArray[i].translation.filter(
							(item: any) => item.languageCode === 'en'
						)?.[0].name
					);
				}

				return (
					<Tooltip title={arr.join(', ')}>
						<div className={styles.workBreak}>{arr.join(', ') || '-'}</div>
					</Tooltip>
				);
			},
		},

		{
			field: 'startDate',
			headerName: 'Start Date',
			width: 200,
			valueGetter: (params: GridValueGetterParams) => `
			${getArabicTime(params?.row?.startDate, 'date')}`,
		},
		{
			field: 'expiryDate',
			headerName: 'End Date',
			width: 200,
			valueGetter: (params: GridValueGetterParams) => `
			${getArabicTime(params?.row?.expiryDate, 'date')}`,
		},

		{
			field: 'entryDate',
			headerName: 'Entry Date',
			width: 200,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={getArabicTime(params?.row?.createdAt, 'date')}>
					<span className='textWrap'>
						{getArabicTime(params?.row?.createdAt, 'date')}
					</span>
				</Tooltip>
			),
		},
		{
			field: 'entryTime',
			headerName: 'Entry Time',
			width: 150,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={getArabicTime(params?.row?.createdAt, 'time')}>
					<span className='textWrap'>
						{getArabicTime(params?.row?.createdAt, 'time')}
					</span>
				</Tooltip>
			),
		},
		{
			field: 'updatedEntryDate',
			headerName: 'Updated Date',
			width: 200,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={getArabicTime(params?.row?.updatedAt, 'date')}>
					<span className='textWrap'>
						{getArabicTime(params?.row?.updatedAt, 'date')}
					</span>
				</Tooltip>
			),
		},
		{
			field: 'updatedEntryTime',
			headerName: 'Updated Time',
			width: 150,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={getArabicTime(params?.row?.updatedAt, 'time')}>
					<span className='textWrap'>
						{getArabicTime(params?.row?.updatedAt, 'time')}
					</span>
				</Tooltip>
			),
		},
		{
			field: 'status',
			headerName: 'Status',
			width: 120,
			renderCell: (params: GridValueGetterParams) => (
				<Chip
					label={params.row.status ? 'Active' : 'Inactive'}
					color={`${params.row.status ? 'success' : 'error'}`}
				/>
			),
		},

		{
			field: 'trending',
			headerName: 'Trending',
			sortable: false,
			width: 160,
			renderCell: (params: GridValueGetterParams) => (
				<Stack direction='row' spacing={2}>
					<Switch
						checked={
							params?.row?.views.filter(
								(item: any) => item.entityType === 'coupons'
							)?.[0].isTrending === 1
						}
						onChange={() => {
							const isTrending = params?.row?.views.filter(
								(item: any) => item.entityType === 'coupons'
							)?.[0]?.isTrending;
							const id = params?.row?.id;
							const isTrend: number = isTrending === 1 ? 0 : 1;
							// eslint-disable-next-line no-nested-ternary
							const tab: number = values === 2 ? 3 : values === 0 ? 2 : 1;
							const x = {
								page: pagination.currentPage,
								entityId: id,
								entityType: 'coupons',
								isTrending: isTrend,
								setState: toggleLoading,
								getAll: getAllCoupon,
								tab,
								query,
								statusFilter,
								partnerFilter,
								sortBy: 'newest',
							};
							if (params.row?.status || (isTrending && !params.row?.status)) {
								dispatch(toggleTrending(x));
							} else {
								toast.error('First make the category active ');
							}
						}}
						color='success'
						inputProps={{ 'aria-label': 'controlled' }}
					/>
				</Stack>
			),
		},
		{
			field: 'action',
			headerName: 'Action',
			sortable: false,
			width: 160,
			renderCell: (params: GridValueGetterParams) => (
				<Stack direction='row' spacing={2}>
					<Link to={`/coupon-management/edit/${values}/${params?.row?.id}`}>
						<IconButton>
							<EditRounded />
						</IconButton>
					</Link>
					<Switch
						checked={!!params.row.status}
						onChange={() => {
							const { id, status } = params.row;
							const sendStatus: number = status === 1 ? 0 : 1;
							const tab: number = values === 2 ? 3 : values === 0 ? 2 : 1;

							// toggleActive(id, sendStatus);
							// dispatch(toggleStatus(id));
							dispatch(
								DeleteCoupon({
									id,
									sendStatus,
									tab,
									statusFilter,
									partnerFilter,
									query: query || '',
								})
							);
						}}
						inputProps={{ 'aria-label': 'controlled' }}
					/>
				</Stack>
			),
		},
	];

	return (
		<TableLayout>
			<Header title='Coupon Management'>
				<Box>
					<Stack direction='row' spacing={2} alignItems='center'>
						<Input
							size='small'
							fullWidth
							id='partner'
							name='partner'
							variant='outlined'
							select
							value={searchParams?.partner ?? partnerFilter}
							onChange={e => {
								const value = Number(e?.target?.value);
								setPartnerFilter(value);
								setParams('partner', value);
							}}
						>
							<MenuItem value={2}>All</MenuItem>
							<MenuItem value={1}>Affiliate</MenuItem>
							<MenuItem value={0}>Direct</MenuItem>
							<MenuItem value={3}>Qyubic Affiliate</MenuItem>
						</Input>
						<Input
							size='small'
							fullWidth
							id='status'
							name='status'
							variant='outlined'
							select
							value={searchParams?.filter ?? statusFilter}
							onChange={e => {
								const sendStatus = Number(e?.target?.value);
								setStatusFilter(() => sendStatus);
								setParams('filter', sendStatus);
							}}
						>
							<MenuItem value={2}>All</MenuItem>
							<MenuItem value={1}>Active</MenuItem>
							<MenuItem value={0}>Inactive</MenuItem>
						</Input>
						<Input
							placeholder='Search by brand name, coupon code'
							InputProps={{
								startAdornment: (
									<InputAdornment position='start'>
										<SearchRounded />
									</InputAdornment>
								),
							}}
							sx={{ width: '18rem' }}
							onChange={(e: any) => {
								const { value } = e.target;
								setQuery(value);
								setParams('query', value);
								debouncedFn(value);
							}}
							value={searchParams?.query}
						/>
						<Link to='/coupon-management/add'>
							<Button startIcon={<AddRounded />} variant='contained'>
								Add
							</Button>
						</Link>
						{/* <Button startIcon={<GetAppRounded />} variant='contained'>
							Export
						</Button> */}
					</Stack>
				</Box>
			</Header>

			<Tabs value={values} onChange={handleChange} aria-label='coupon tabs'>
				<Tab label='Coupons' style={{ padding: '0px' }} disabled={loading} />
				<Tab label='Cashback' style={{ marginLeft: '5px' }} disabled={loading} />
				<Tab label='Offer' style={{ marginLeft: '5px' }} disabled={loading} />
			</Tabs>

			<TabPanel index={0} value={values}>
				<Table
					rows={allCoupons}
					columns={couponTable}
					count={pagination?.totalPages}
					page={pagination?.currentPage}
					rowCount={pagination?.perPage}
					total={pagination?.total}
					perPage={pagination?.perPage}
					onPerPage={(value: number) => {
						dispatch(couponSlice.actions.setCurrentPage(1));
						dispatch(couponSlice.actions.setPerPage(value));
					}}
					onChange={(_e: ChangeEvent, value: number) =>
						dispatch(couponSlice.actions.setCurrentPage(value))
					}
					sx={{ height: '70vh' }}
					loading={loading}
					rowHeight={85}
				/>
			</TabPanel>
			<TabPanel index={1} value={values}>
				<Table
					rows={allCoupons}
					columns={couponTable}
					count={pagination?.totalPages}
					page={pagination?.currentPage}
					rowCount={pagination?.perPage}
					total={pagination?.total}
					perPage={pagination?.perPage}
					onPerPage={(value: number) => {
						dispatch(couponSlice.actions.setCurrentPage(1));
						dispatch(couponSlice.actions.setPerPage(value));
					}}
					onChange={(_e: ChangeEvent, value: number) =>
						dispatch(couponSlice.actions.setCurrentPage(value))
					}
					sx={{ height: '70vh' }}
					rowHeight={85}
					loading={loading}
				/>
			</TabPanel>
			<TabPanel index={2} value={values}>
				<Table
					rows={allCoupons}
					rowHeight={85}
					columns={couponTable}
					count={pagination?.totalPages}
					page={pagination?.currentPage}
					rowCount={pagination?.perPage}
					total={pagination?.total}
					perPage={pagination?.perPage}
					onPerPage={(value: number) => {
						dispatch(couponSlice.actions.setCurrentPage(1));
						dispatch(couponSlice.actions.setPerPage(value));
					}}
					onChange={(_e: ChangeEvent, value: number) =>
						dispatch(couponSlice.actions.setCurrentPage(value))
					}
					sx={{ height: '70vh' }}
					loading={loading}
				/>
			</TabPanel>
		</TableLayout>
	);
};
export default Coupon;
