/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-plusplus */
/* eslint-disable react/no-unstable-nested-components */
import { AddRounded, EditRounded, SearchRounded } from '@mui/icons-material';
import {
	Box,
	Chip,
	IconButton,
	InputAdornment,
	MenuItem,
	Stack,
	Switch,
	Tooltip,
} from '@mui/material';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { DeleteBanner, getAllbanners } from 'api/banners';
import { getAllBrandsWithoutPage } from 'api/brand';
import { getallCategorieswithoutPage } from 'api/categories';
import { getAllCountry } from 'api/seeder';
// import { getAllBrand } from 'api/brand';
// import { getAllCategory } from 'api/categories';
import Button from 'components/common/Button';
import Header from 'components/common/Header';
import Input from 'components/common/Input';
import Table from 'components/common/Table';
import TableLayout from 'components/common/TableLayout';
import { debounce } from 'lodash';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { bannerSlice, emptySingleBanner } from 'redux/banner/bannerSlice';
import { RootState } from 'redux/store';
import { bannerPages, bannerSubPages, disablefilter } from 'utils/constant';
import getArabicTime from 'utils/dateConverter';
import useUrlSearchParams from 'utils/useUrlSearchParams';

import styles from './index.module.scss';

const Banner = () => {
	// const [page, setPage] = useState(1);
	const { searchParams, setParams } = useUrlSearchParams(
		'filter',
		'pageType',
		'countries',
		'query'
	);
	const [statusFilter, setStatusFilter] = useState(searchParams?.filter ?? 1);
	const [pageFilter, setPageFilter] = useState(searchParams?.pageType ?? 0);
	const [selectedCountry, setselectedCountry] = useState(
		searchParams?.countries ?? ''
	);
	const [query, setQuery] = useState<string>(searchParams?.query ?? undefined);

	const dispatch = useDispatch();
	const { allBanner, loading, pagination } = useSelector(
		(state: RootState) => state.banner
	);
	const allCountry = useSelector((state: RootState) => state.country.allCountry);

	useEffect(() => {
		dispatch(getAllCountry());
		dispatch(
			getallCategorieswithoutPage({
				page: pagination?.currentPage,
				perPage: pagination?.perPage,
			})
		);
		dispatch(
			getAllBrandsWithoutPage({
				page: pagination?.currentPage,
				countries: [],
				perPage: pagination?.perPage,
			})
		);
	}, []);

	const columns: GridColDef[] = [
		{
			field: 'brand',
			headerName: 'Brand Name',
			width: 150,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={params.row.brandMeta?.en[0].name}>
					<span className='textWrap'>{params.row.brandMeta?.en[0].name}</span>
				</Tooltip>
			),
		},

		{
			field: 'category',
			headerName: 'Category',
			width: 200,
			renderCell: (params: GridValueGetterParams) => {
				const categoryArray = params?.row?.categoryMeta;
				const arr = [];

				for (let i = 0; i < categoryArray.length; i++) {
					arr.push(
						categoryArray[i].translation.filter(
							(item: any) => item.languageCode === 'en'
						)[0].name
					);
				}

				return (
					<Tooltip title={arr.join(', ')}>
						<div className={styles.workBreak}>{`${arr.join(', ') || 'N/A'}`}</div>
					</Tooltip>
				);
			},
		},

		{
			field: 'imageSlug',
			...disablefilter,
			headerName: 'Banner',
			width: 300,
			renderCell: (params: GridValueGetterParams) => (
				<Box width='200px' height='100px'>
					<img
						src={
							Object.keys(params?.row?.translation).length !== 0
								? `${params.row.translation?.en[0]?.imageSlug?.path}`
								: 'logo.png'
						}
						alt=''
						width='100%'
						height='100px'
						style={{
							backgroundPosition: 'center center',
							objectFit: 'contain',
							padding: '0.5rem',
						}}
					/>
				</Box>
			),
		},
		{
			field: 'imageSlugAra',
			...disablefilter,
			headerName: 'Banner (Arabic)',
			width: 300,
			renderCell: (params: GridValueGetterParams) => (
				<Box width='200px' height='100px'>
					<img
						src={
							Object.keys(params?.row?.translation).length !== 0
								? `${params.row.translation?.ara[0]?.imageSlug?.path}`
								: 'logo.png'
						}
						alt=''
						width='100%'
						height='100px'
						style={{
							backgroundPosition: 'center center',
							objectFit: 'contain',
							padding: '0.5rem',
						}}
					/>
				</Box>
			),
		},
		{
			field: 'link',
			headerName: 'Link',
			...disablefilter,
			width: 200,
			// valueGetter: (params: GridValueGetterParams) => params.row.link,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={params?.row?.link || 'N/A'}>
					<span className='textWrap'>
						{params?.row?.link.toLowerCase() || 'N/A'}
					</span>
				</Tooltip>
			),
		},
		{
			field: 'page',
			headerName: 'Page',
			...disablefilter,
			width: 200,
			renderCell: (params: GridValueGetterParams) => {
				console.log(params.row.page);
				type BannerPageKeys = keyof typeof bannerPages;
				const page = params?.row?.page as BannerPageKeys | undefined; // Cast to match keys or allow undefined
				const pageTitle = page ? bannerPages[page] : '-';

				return (
					<Tooltip title={pageTitle}>
						<span className='textWrap'>{pageTitle}</span>
					</Tooltip>
				);
			},
		},
		{
			field: 'subPage',
			headerName: 'Sub Page',
			...disablefilter,
			width: 200,
			renderCell: (params: GridValueGetterParams) => {
				type BannerPageKeys = keyof typeof bannerSubPages;
				const subPage = params?.row?.subPage as BannerPageKeys | undefined; // Cast to match keys or allow undefined
				const pageTitle = subPage ? bannerSubPages[subPage] : '-';
				return (
					<Tooltip title={pageTitle}>
						<span className='textWrap'>{pageTitle}</span>
					</Tooltip>
				);
			},
		},
		{
			field: 'position',
			headerName: 'Position',
			...disablefilter,
			width: 120,
			valueGetter: (params: GridValueGetterParams) => `${params.row.position}`,
		},
		{
			field: 'priority',
			headerName: 'Priority',
			...disablefilter,
			width: 120,
			valueGetter: (params: GridValueGetterParams) =>
				`${params.row.priority || 'NA'}`,
		},
		{
			field: 'country',
			headerName: 'Country',
			width: 200,
			renderCell: (params: GridValueGetterParams) => {
				const countryArray = params?.row?.country;
				const arr = [];
				for (let i = 0; i < countryArray.length; i++) {
					arr.push(
						countryArray[i]?.translation?.filter(
							(item: any) => item.languageCode === 'en'
						)[0]?.name
					);
				}

				return (
					<Tooltip title={arr.length ? `${arr.join(', ')}` : '-'}>
						<div className={styles.workBreak}>
							{arr.length ? `${arr.join(', ')}` : '-'}
						</div>
					</Tooltip>
				);
			},
		},
		{
			field: 'entryDate',
			headerName: 'Entry Date',
			width: 200,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={getArabicTime(params?.row?.createdAt, 'date')}>
					<span className='textWrap'>
						{getArabicTime(params?.row?.createdAt, 'date')}
					</span>
				</Tooltip>
			),
		},
		{
			field: 'entryTime',
			headerName: 'Entry Time',
			width: 150,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={getArabicTime(params?.row?.createdAt, 'time')}>
					<span className='textWrap'>
						{getArabicTime(params?.row?.createdAt, 'time')}
					</span>
				</Tooltip>
			),
		},
		{
			field: 'status',
			headerName: 'Status',
			width: 120,
			...disablefilter,
			renderCell: (params: GridValueGetterParams) => (
				<Chip
					label={params.row.status ? 'Active' : 'Inactive'}
					color={`${params.row.status ? 'success' : 'error'}`}
				/>
			),
		},
		{
			field: 'action',
			headerName: 'Action',
			...disablefilter,
			sortable: false,
			width: 160,
			renderCell: (params: GridValueGetterParams) => (
				<Stack direction='row' spacing={2}>
					<Link to={`/banner-management/edit/${params.row.id}`}>
						<IconButton>
							<EditRounded />
						</IconButton>
					</Link>
					<Switch
						checked={!!params.row.status}
						onChange={() => {
							const { id, status } = params.row;
							const sendStatus: number = status === 1 ? 0 : 1;
							// dispatch(toggleStatus(id));
							dispatch(
								DeleteBanner({
									id,
									sendStatus,
									statusFilter,
									query: query || '',
									pageNumber: pageFilter,
								})
							);
						}}
						inputProps={{ 'aria-label': 'controlled' }}
					/>
				</Stack>
			),
		},
	];
	const fetchBanners = (q?: string) => {
		dispatch(
			getAllbanners({
				page: pagination?.currentPage,
				statusFilter,
				q: q ?? query,
				pageNumber: pageFilter,
				perPage: pagination.perPage,
				countries: selectedCountry,
			})
		);
		dispatch({ type: emptySingleBanner });
	};

	useEffect(() => {
		fetchBanners();
	}, [
		pagination.currentPage,
		statusFilter,
		pageFilter,
		pagination.perPage,
		selectedCountry,
	]);

	const debouncedFn = useCallback(
		debounce(q => fetchBanners(q), 500),
		[]
	);
	return (
		<TableLayout>
			<Header title='Banner Management'>
				<Box>
					<Stack direction='row' spacing={2} alignItems='center'>
						<Input
							size='small'
							fullWidth
							id='countryFilter'
							name='countryFilter'
							variant='outlined'
							select
							value={selectedCountry}
							onChange={e => {
								const countryId = e?.target?.value;
								setselectedCountry(countryId);
								setParams('countries', countryId);
								dispatch(bannerSlice.actions.setCurrentPage(1));
							}}
							key={searchParams.countries}
						>
							<MenuItem value=''>NONE</MenuItem>
							{allCountry.map((each: any) => (
								<MenuItem key={each.id} value={each.id}>
									{each.code || 'Country'}
								</MenuItem>
							))}
						</Input>
						<Input
							size='small'
							fullWidth
							id='pageFilter'
							name='pageFilter'
							variant='outlined'
							select
							value={pageFilter}
							onChange={e => {
								const bannerPage = Number(e?.target?.value);
								setPageFilter(bannerPage);
								setParams('pageType', bannerPage);
								dispatch(bannerSlice.actions.setCurrentPage(1));
							}}
							key={searchParams.pageType}
						>
							<MenuItem value={0}>All</MenuItem>
							<MenuItem value={1}>Home</MenuItem>
							<MenuItem value={2}>Category</MenuItem>
							<MenuItem value={3}>Brand</MenuItem>
							<MenuItem value={7}>Offer Listing</MenuItem>
							<MenuItem value={8}>Coupon Listing</MenuItem>
						</Input>
						<Input
							size='small'
							fullWidth
							id='status'
							name='status'
							variant='outlined'
							select
							value={statusFilter}
							onChange={e => {
								const sendStatus = Number(e?.target?.value);
								setStatusFilter(sendStatus);
								setParams('filter', sendStatus);
							}}
							key={searchParams.filter}
						>
							<MenuItem value={2}>All</MenuItem>
							<MenuItem value={1}>Active</MenuItem>
							<MenuItem value={0}>Inactive</MenuItem>
						</Input>
						<Input
							sx={{ width: '18rem' }}
							fullWidth
							onChange={(e: any) => {
								setQuery(e.target.value);
								setParams('query', e.target.value);
								debouncedFn(e.target.value);
							}}
							value={query}
							placeholder='Search by brand/category'
							InputProps={{
								startAdornment: (
									<InputAdornment position='start'>
										<SearchRounded />
									</InputAdornment>
								),
							}}
						/>
						<Link to='/banner-management/add'>
							<Button startIcon={<AddRounded />} variant='contained'>
								Add
							</Button>
						</Link>
					</Stack>
				</Box>
			</Header>

			<Table
				rows={allBanner}
				columns={columns}
				rowHeight={80}
				loading={loading}
				total={pagination?.total}
				count={pagination?.totalPages}
				page={pagination?.currentPage}
				perPage={pagination?.perPage}
				onPerPage={(value: number) => {
					dispatch(bannerSlice.actions.setCurrentPage(1));
					dispatch(bannerSlice.actions.setPerPage(value));
				}}
				onChange={(_e: ChangeEvent, value: number) =>
					dispatch(bannerSlice.actions.setCurrentPage(value))
				}
				sx={{ height: '70vh' }}
			/>
		</TableLayout>
	);
};
export default Banner;
